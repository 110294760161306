import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Main from './Main';
import { ThemeProvider, createTheme } from "@mui/material/styles"
import '@fontsource/quicksand'

const theme = createTheme({
  typography: {
    fontFamily: 'Quicksand, Verdana, Roboto, "Helvetica Neue"',
    fontSize: 12,
  },
});


/* <Route path="/" element={<Main />} />
          <Route path="project" element={<App />} />
          <Route path="project/:projectId" element={<App />} /> */

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
          <Route exact path="/"><Main /></Route>
          <Route path="/project/:projectId"><App /></Route>
          <Route path="*">
            <Main />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

