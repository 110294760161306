import "./App.css";
import React, { useState } from "react";
import { Prompt, useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PlayArrow from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
import { Alert } from "@mui/material";
import TextField from "@mui/material/TextField";

import PlantUmlEncoder from "plantuml-encoder";

import useLocalStorage from './utils/LocalStorage';

import TopBar from "./TopBar";
import ThemeSelector from "./components/ThemeSelector";

import RevisionsList from "./RevisionsList";

function App() {
  let params = useParams();
  const projectId = params.projectId;
  const [database, setDatabase] = useLocalStorage("database");
  const [isBlocking, setIsBlocking] = useState(false);

  if (typeof database.projects[projectId] === 'undefined') {
    database.projects[projectId] = {
      projectId: projectId,
      createDate: Date.now(),
      updateDate: null,
      type: 'Sequence Diagram',
      revisions: [],
      name: '',
      data: PlantUmlEncoder.encode("@startuml\nBob->Alice: Hello World\n@enduml"),
    };
    setDatabase(database);
  }

  const [data, setData] = useState(`${PlantUmlEncoder.decode(database.projects[projectId].data)}`);

  const plantBaseUrl = "https://plantuml.com/plantuml/png/";
  // const [data, setData] = useState(
  //   PlantUmlEncoder.decode(projects[projectId].data)
  // );
  const [image, setImage] = useState(
    plantBaseUrl + database.projects[projectId].data
  );

  const [title, setTitle] = useState(database.projects[projectId].name);

  const [theme, setTheme] = useState(typeof database.projects[projectId].theme === 'undefined' ? 'null' : database.projects[projectId].theme);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const saveData = () => {

    if (database.projects[projectId].data === data) {
      setAlertMessage("Diagram unchanged. No revision created");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
      return;
    }
    // don't use revisions.length as we may allow deleting of versions
    const revision = {
      data: PlantUmlEncoder.encode(data),
      ts: Date.now()
    };
    database.projects[projectId].name = title;
    database.projects[projectId].theme = theme;
    database.projects[projectId].updateDate = Date.now();
    database.projects[projectId].revisions.push(revision);
    database.projects[projectId].data = PlantUmlEncoder.encode(data);
    setDatabase(database);
    setIsBlocking(false);
  };


  const updateTitle = (text) => {
    setIsBlocking(true);
    setTitle(text);
    if (text.length === 0)
      updateDataAndImage(data.replace(/title.*\n/g, ""));
    else
      updateDataAndImage(data.replace(/title.*\n/g, "").replace(/@startuml\n/, `@startuml\ntitle ${text}\n`));
  }

  const updateDataAndImage = (updatedData) => {
    setData(updatedData);
    setImage(
      plantBaseUrl + PlantUmlEncoder.encode(updatedData)
    );
  }

  const changeTheme = (e) => {
    setIsBlocking(true);
    let newTheme = e.target.value;
    setTheme(newTheme);
    if (newTheme === 'null')
      updateDataAndImage(data.replace(/!theme.*\n/g, ""));
    else
      updateDataAndImage(data.replace(/!theme.*\n/g, "").replace(/@startuml\n/, `@startuml\n!theme ${newTheme}\n`));
  }

  const try_callback = (data) => {
    updateDataAndImage(PlantUmlEncoder.decode(data));
  }

  return (
    <>
      <Grid container spacing={2}>
        <TopBar />
        <Grid
          container
          style={{
            marginTop: 10,
            paddingLeft: 60,
            paddingRight: 60,
          }}
        >
          <Grid xl={12} style={{ width: "100%", marginBottom: 20 }}>
            <TextField
              id="outlined-fixed"
              label="Diagram Title"
              placeholder="Title for diagram"
              value={title}
              onChange={(event) => updateTitle(event.target.value)}
              style={{ marginBottom: 20 }}
            />
            <ThemeSelector handleChange={changeTheme} selectedTheme={theme} />
            <TextField
              id="outlined-multiline-fixed"
              label="Sequence diagram UML"
              multiline
              value={data}
              maxRows={20}
              style={{ width: "100%" }}
              onChange={(event) => {
                setIsBlocking(true);
                setData(event.target.value);
              }}
            />
          </Grid>
          <Grid xl={12} style={{ width: "100%", marginBottom: 20 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayArrow />}
              onClick={() => setImage(
                plantBaseUrl + PlantUmlEncoder.encode(data)
              )}
            >
              Update
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              style={{ margin: 10 }}
              onClick={saveData}
            >
              Save
            </Button>
            <RevisionsList revisions={[...database.projects[projectId].revisions].reverse()} try_callback={try_callback} />
          </Grid>
          <Grid style={{ width: "100%" }}>
            <img src={image} alt="Diagram" style={{ maxWidth: "100%" }} />
          </Grid>
        </Grid>
      </Grid>
      {
        showAlert ?
          <Alert
            variant="filled"
            severity="warning"
            onClose={() => { setShowAlert(false) }}
            style={{ width: 'auto', position: 'absolute', top: 10, right: 10 }}
          >
            {alertMessage}
          </Alert> : ''
      }
      <Prompt
        when={isBlocking}
        message={"You have unsaved changes. Click 'Cancel' and click the 'Save' button to ensure that the data is not lost."}
      />
    </>
  );
}

export default App;
